/* eslint-disable @nx/enforce-module-boundaries */
import { HStack, Heading, Stack, VStack, Image, Text, Box, Button } from '@mybridge/ui';
import Link from 'next/link';

export default function NotFound() {
  return (
    <Stack
      width="100%"
      h="90vh"
      alignItems="center"
      justifyContent="center"
    >
      <HStack justifyContent="space-between" width="80%">
        <VStack alignItems="flex-start" flex="auto" gap={8}>
          <Heading fontSize="6xl" fontWeight="300">
            Something&apos;s wrong here...
          </Heading>
          <Text fontSize="3xl">
            We can&apos;t find the page you&apos;re looking for. Check out <br/>Jobs
            or head back to home
          </Text>
          <HStack>
            <Link href='/jobs/suggested'><Button variant="primary" size="lg">Jobs</Button></Link>
            <Link href='/'><Button variant="primaryOutline" size="lg">Home</Button></Link>
          </HStack>
        </VStack>
        <HStack flex="auto" justifyContent="center">
          <Image
            src="/images/questionmark.svg"
            alt="questionmark"
            width="300px"
          />
        </HStack>
      </HStack>
    </Stack>
  );
}
